import ApiConstants from "../constants/ApiConstants";
import http from "../http";

class InviteService {
  static getPeopleTenant() {
    return http.get(ApiConstants.URL.PEOPLE.GET_TENANT_INFO);
  }

  static getInvites() {
    return http.get(ApiConstants.URL.INVITE.GET);
  }

  static acceptInvite(inviteCode) {
    return http.post(`${ApiConstants.URL.INVITE.ACCEPT}/${inviteCode}`);
  }

  static rejectInvite(inviteId) {
    return http.delete(`${ApiConstants.URL.INVITE.REJECT}/${inviteId}`);
  }
}

export default InviteService;
