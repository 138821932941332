import AppManager from "./AppManager";
import PlanManager from "./PlanManager";
import AddOnManager from "./AddOnManager";
import IntercomManager from "./IntercomManager";

export const PAGE_ROUTES = {
  HOME: "/",
  USERS: "/users",
  BILLING: "/billing",
  BILLING_WITH_ACTION: "/billing/:action",
  PLAN_SELECTION: "/plans",
  SECURITY: "/security",
  SETTINGS: "/settings",
  COMPANY: "/company",
  CHECKOUT: "/checkout",
  BOOKKEEPER_CHECKOUT: "/checkout/bookkeeper",
  REPORTS: "/reports",
  INVITES: "/invites",
};
export default class RouteManager {
  static presenter = null;
  static setPresenter(presenter) {
    this.presenter = presenter;
    IntercomManager.trackEvent("page", { url: window.location.pathname });
  }
  static navigateToPage(pageRoute, param = null) {
    RouteManager.presenter.props.history.push(
      pageRoute + (param ? "?" + param : "")
    );
    AppManager.scrollToTop();
    IntercomManager.trackEvent("page", { url: window.location.pathname });
  }

  static navigateToHome() {
    RouteManager.navigateToPage(PAGE_ROUTES.HOME);
  }

  static gotoCheckout(
    product = null,
    plan = null,
    interval = null,
    addons = null,
    tenantID = null,
    userID = null
  ) {
    let para_product = product ? product : PlanManager.getDefaultProduct();
    let para_plan = plan ? plan : PlanManager.getDefaultPlan();
    let para_interval = interval ? interval : PlanManager.getDefaultInterval();

    AddOnManager.setAddOns(addons);

    let url_para =
      "product=" +
      para_product +
      "&plan=" +
      para_plan +
      "&interval=" +
      para_interval;

    if (tenantID !== "" && tenantID !== null && userID !== "" && userID !== null) {
      let para = "&UserID="+userID+"&TenantID="+tenantID;
      let new_url_para = url_para + para;
      RouteManager.navigateToPage(PAGE_ROUTES.BOOKKEEPER_CHECKOUT, new_url_para);
    } else {
      RouteManager.navigateToPage(PAGE_ROUTES.CHECKOUT, url_para);
    }
  }
}
