import { INPUT_TYPE, DKLabel } from "deskera-ui-library";
import InviteService from "../services/InviteService";

class InviteManager {
  static IS_PEOPLE_PW_TENANT = false;

  static COLUMN = [
    {
      key: "sn",
      name: "SN",
      type: INPUT_TYPE.NUMBER,
      width: 60,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
    },
    {
      key: "inviterName",
      name: "INVITER_NAME",
      type: INPUT_TYPE.TEXT,
      width: 250,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
    },
    {
      key: "inviterEmail",
      name: "INVITER_EMAIL",
      type: INPUT_TYPE.TEXT,
      width: 350,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
    },
    {
      key: "orgInvitedTo",
      name: "ORGNIZATION",
      type: INPUT_TYPE.TEXT,
      width: 250,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
    },
    {
      key: "appName",
      name: "APP",
      type: INPUT_TYPE.TEXT,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      renderer: (data) => {
        return (
          <div className={`bg-chip-green text-green border-green p-v-xs p-h-s border-radius-l fw-m text-wrap-none m-s`} >
            <DKLabel text={data.value} className="fw-m" />
          </div>
        );
      },
    },
    {
      key: "action",
      name: "ACTIONS",
      type: INPUT_TYPE.BUTTON,
      width: 250,
      classname: "justify-content-center",
      options: [],
    },
  ];

  static getColumns() {
    return this.COLUMN;
  }

  static async setIsPeoplePWTenant() {
    const response = await InviteService.getPeopleTenant();
    if (response?.status === "OK" && response?.tenant) {
      this.IS_PEOPLE_PW_TENANT =
        response.tenant.customSectionFieldsEnabled || false;
    }
  }
}

export default InviteManager;
