import ApiManager from "./ApiManager";
import Subscription from "../services/subscription";
import User from "../services/user";
import UserManager from "./UserManager";
import IAMManager from "./IAMManager";
import Tenant from "../services/tenant";
import SubscriptionManager from "./SubscriptionManager";
import { createOrUpdateContact } from "../managers/ActiveCampaignWrapper";
import ApiConstants from "../constants/ApiConstants";
import {
  showLoader,
  removeLoader,
} from "deskera-ui-library";
import { store } from "../redux/store";
import { TableManger } from "./TableManager";
import { fetchTables } from "../redux/slices/tableSlice";
import Payload from "../constants/Payload";
import PlanManager from "./PlanManager";
import { INTERVALS, PRODUCTS, SUBSCRIPTION_STATUS } from "../constants/Enum";
import InviteManager from "./InviteManager";

export default class InitialApiManager {
  static init(onSuccess, onFail) {
    ApiManager.checkIfUserLoggedIn(
      () => {
        if (UserManager.isOrgSet() === false) {
          onFail({ errorMessage: "Org not set" });
        } else {
          this.callInitialApis(onSuccess);
        }
      },
      () => {
        onFail();
      }
    );
  }

  static callInitialApis = (callback) => {
    this.fetchUUID();

    showLoader("Fetching user details...");
    User.getUserInfo({ id: UserManager.getUserID() })
      .then(async (res) => {
        InitialApiManager.getTableData().then((data) => {})
        let userData = UserManager?.getUserDetails();
        userData = { ...userData, roleShortInfo: res.content[0].roleShortInfo };
        userData.name =
          res.content[0].firstName + " " + res.content[0].lastName;
        userData.email = res.content[0].email;
        userData.phone = res.content[0].contact;

        UserManager.setUserDetails(userData);

        IAMManager.setTFAStatus(res.content[0].mfaenabled);

        SubscriptionManager.getCountryCodeFromLocation();

        InviteManager.setIsPeoplePWTenant();

        createOrUpdateContact({
          contact: {
            email: userData.email,
            firstName: userData.name,
            lastName: userData.lastName,
            phone: userData.phone,
            fieldValues: [],
          },
        });

        User.getLoggedInUserPermissions();
        
        const promises = [
          User.getUsersPermissions([UserManager.getUserID()]),
          Tenant.getTenantDetails(),
          Subscription.getDetails()
        ];

        let currentSubscriptionStatus;
        const responses = await Promise.allSettled(promises);
        responses.forEach((response, index) => {
          if (index === 0 && response.status === "fulfilled") {
            UserManager.setUsersPermission(response?.value[0]);
          }
          if (index === 2 && response?.value && response.value[Payload.PLAN.AIO]) {
            currentSubscriptionStatus = SubscriptionManager.getSubscriptionStatusForNewERP(response.value[Payload.PLAN.AIO]);
          }
        });

        if (currentSubscriptionStatus === SUBSCRIPTION_STATUS.TRIAL_PENDING) {
          try {
            const planID = PlanManager.getPlanID(PRODUCTS.ERP_V2, PlanManager.getDefaultPlanForERP(), INTERVALS.YEARLY);
            const params = {
              PlanID: planID,
              Currency: SubscriptionManager.getCurrencySameAsActivePlanCurrency().toLowerCase()
            };

            await Subscription.activatePlan(params, true);
          } catch { }
        }

        callback();
      })
      .catch((err) => {
        removeLoader();
        callback(err);
      });
  };

  static fetchUUID() {
    fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.CRISP.UUID,
      ApiManager.getApiRequestOptions("GET")
    )
      .then((response) => response.json())
      .then((data) => {
        UserManager.setUUID(data.uuid);
      })
      .catch((error) => {});
  }
  static getTableData() {
    return store.dispatch(fetchTables()).then(
      (data) => {
        TableManger.populateViewMap(
          JSON.parse(JSON.stringify(data.payload)).filter(
            (data) => !data.default && data.columnsMetaData
          )
        ); //TODO: need to change this
        // PipelineManager.get((data) => { });
        return Promise.resolve(JSON.parse(JSON.stringify(data.payload)));
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
}
