import React, { Component } from "react";
import "./css/Common.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LeftMenu from "./components/menu/LeftMenu";
import TopMenu from "./components/menu/TopMenu";
import AdminDashboard from "./components/main-dashboard/AdminDashboard";
import UsersDashboard from "./components/users/UsersDashboard";
import BillingDashboard from "./components/billing/BillingDashboard";
import SecurityDashboard from "./components/security/SecurityDashboard";
import ReportsDashboard from "./components/reports/ReportsDashboard";
import BookkeeperCheckout from "./components/bookkeeperCheckout/BookkeeperCheckout";

import SettingsDashboard from "./components/settings/SettingsDashboard";
import PlanSelection from "./components/plan-selection/PlanSelection";
import Checkout from "./components/checkout/Checkout";

import InitialApiManager from "./managers/InitialApiManager";
import UserManager from "./managers/UserManager";
import IAM from "./services/iam";
import { IntercomAPI } from "react-intercom";
import MixPanelManager from "./managers/common/MIxpanelManger";
import User from "./services/user";
import { isMobileAppWebView } from "./utility/ViewportSizeUtils";

import { showLoader, removeLoader, showAlert } from "deskera-ui-library";

import SideBarService from "./services/sidebar";

import WalkthroughTooltip from "./components/common/WalkthroughTooltip";
import { walkthrough } from "./constants/Walkthrough";
import { Analytics } from "./utility/GTMEvents";
import { LANGUAGES_CODE } from "./constants/Constant";
import TimeoutManager from "./managers/TimeoutManager";
import ApiConstants from "./constants/ApiConstants";
import InactivityTimer from "./components/inactivity-timer/InactivityTimer";
import NewPlanDetails from "./components/plan-details";
import SubscriptionManager from "./managers/SubscriptionManager";
import { PAGE_ROUTES } from "./managers/RouteManager";
import InviteList from "./components/invites/InviteList";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didInitialApiResponseReceived: false,
      isOrgAvailable: null,
      path: window.location.pathname,
      emailVerifiedPopupVisible: true,
      defaultLangCode: localStorage.getItem("language") || LANGUAGES_CODE.ENGLISH_US,
      timeoutData: null
    };
    SideBarService.setIsSideBarExpanded();
  }
  load_cookie_pref() {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://tracker.deskera.com/cookie-consent.min.js";
    document.head.appendChild(script);

    let script_custom = document.createElement("script");
    script_custom.textContent =
      'document.addEventListener("DOMContentLoaded", function() { cookieconsent.run({ notice_banner_type: "simple", consent_type: "express",palette: "light",language: "en", cookies_policy_url: "https://www.deskera.com/cookie-policy"});});';
    document.head.appendChild(script_custom);
  }

  componentDidMount() {
    this.load_cookie_pref();
    this.mountActiveCampaignScript();
    // this.mountCrispChatbotScript();
    showLoader();
    InitialApiManager.init(this.initialAPIReceived, this.initialAPIFailed);

    var pushState = window.history.pushState;
    window.history.pushState = function (state) {
      this.setState({
        path: window.location.pathname,
      });
      return pushState.apply(window.history, arguments);
    }.bind(this);
    if (typeof window !== undefined) {
      Analytics.initGoogleGTM();
    }
  }

  initialAPIReceived = (err) => {
    if (
      UserManager?.getUserDetails() &&
      !UserManager?.getUserDetails().emailVerified &&
      this.state.emailVerifiedPopupVisible
    ) {
      this.setState(
        {
          emailVerifiedPopupVisible: false,
        },
        () => this.showEmailVerificationPopup()
      );
    }
    this.setState({
      isOrgAvailable: true,
      didInitialApiResponseReceived: true
    }, this.onInitialApiResponseReceived);

    removeLoader();
    this.addCookieConsent();
    MixPanelManager.init(UserManager?.getUserDetails());
    MixPanelManager.openGO();

    // if(!isMobileAppWebView()) {
    //   this.mountCrispChatbotScript();
    // }

    setTimeout(() => {
      this.addIntercom();
    }, 1000);
  };

  onInitialApiResponseReceived() {
    if (ApiConstants.TIMEOUT_ENABLED) {
      TimeoutManager.getIdleTimeoutTokenExpiry().then(
        (response) => {
          if (response.id) {
            TimeoutManager.setTimeoutData(response);
          } else {
            // set default data
            TimeoutManager.setTimeoutData(TimeoutManager.TIMEOUT_DATA);
          }
        }, (error) => {
          console.log(error);
          // set default data
          TimeoutManager.setTimeoutData(TimeoutManager.TIMEOUT_DATA);
        },
      ).finally(() => {
        this.setState({
          timeoutData: TimeoutManager.getTimeoutData()
        });
      });
    }
  }

  initialAPIFailed = (err = null) => {
    removeLoader();
    if (err && err.errorMessage === "Org not set") {
      this.setState({
        isOrgAvailable: false,
      });
    } else {
      showAlert("Error!", "Server error occurred.");
    }
  };

  mountActiveCampaignScript() {
    // load script for site tracking active campaign
    const script = document.createElement("script");
    script.src = `<script type="text/javascript">
    (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
    vgo('setAccount', ${process.env.REACT_APP_AC_ACCOUNT_ID});
    vgo('setTrackByDefault', true);

    vgo('process');
</script>`;
    document.body.appendChild(script);
  }

  mountCrispChatbotScript() {
    window.$crisp = [];
    window.CRISP_TOKEN_ID = UserManager.getUUID();
    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    window.$crisp.push(["set", "user:email", UserManager.getUserEmail()]);
  }

  displayWalkthroughView() {
    return <WalkthroughTooltip data={walkthrough} />;
  }

  render() {
    if (window.location.hash.startsWith("#/")) {
      window.location = window.location.hash.replace("#", "");
    }

    return (
      <div className="main-holder bg-gray1 row align-items-start">
        {/* {this.state.didInitialApiResponseReceived && <IntercomDiv />} */}
        {/* {(this.state.didInitialApiResponseReceived ||
          this.state.isOrgAvailable === false) && <LeftMenu />} */}
        {this.state.didInitialApiResponseReceived && this.state.timeoutData &&
          <InactivityTimer
            name={ApiConstants.IDLE_TIMER_ID}
            inactivityTimeoutEnabled={this.state.timeoutData.idleTimeoutEnabled}
            inactivityTimeout={this.state.timeoutData.idleTimeoutValue}
            tokenExpiryNotifyEnabled={this.state.timeoutData.tokenExpiryEnabled}
            tokenExpiryTime={this.state.timeoutData.tokenExpiryTime}
            autoLogoutTime={ApiConstants.AUTO_LOGOUT_TIME}
            refreshTokenUrl={ApiConstants.URL.BASE + ApiConstants.URL.IAM.REFRESH_TOKEN}
            logoutUrl={ApiConstants.URL.BASE + ApiConstants.URL.IAM.LOG_OUT}
            redirectUrl={ApiConstants.URL.IAM.REDIRECT}
          />
        }
        <div className="bg-menu-go parent-height column ">
          {(this.state.didInitialApiResponseReceived ||
            this.state.isOrgAvailable === false) && <LeftMenu />}
        </div>
        <div id="mobile-loader" style={{ zIndex: 9999 }} />
        <div
          className={`parent-height column ${
            isMobileAppWebView() ? " bg-mobile " : "bg-gray1"
          }`}
          style={{ width: window.innerWidth - 230, flex: 1 }}
        >
          {(this.state.didInitialApiResponseReceived ||
            this.state.isOrgAvailable === false) && (
            <TopMenu
              path={this.state.path}
              defaultLangCode={this.state.defaultLangCode}
            />
          )}
          {this.state.didInitialApiResponseReceived && (
            <div
              id="containerDiv"
              className={`contain-wrapper-web display-block pt-r main-holder-p-horizontal column align-items-start  border-box ${
                !isMobileAppWebView() ? "mt-s " : ""
              }`}
            >
              {this.displayWalkthroughView()}
              <Router>
                <Switch>
                  <Route exact path="/" component={AdminDashboard} />

                  {/* /////  DAHSBOARD  ///// */}
                  <Route exact path="/dashboard" component={AdminDashboard} />
                  <Route exact path="/console" component={UsersDashboard} />

                  {/* /////  USER  ///// */}
                  <Route exact path="/users" component={UsersDashboard} />
                  <Route
                    exact
                    path="/usermanagement"
                    component={UsersDashboard}
                  />

                  {/* /////  BILLING  ///// */}
                  <Route exact path="/billing" component={SubscriptionManager.showNewCreditBasedFlow() ? NewPlanDetails : BillingDashboard} />
                  <Route exact path={PAGE_ROUTES.BILLING_WITH_ACTION} component={NewPlanDetails} />
                  <Route
                    exact
                    path="/myaccount/billing"
                    component={BillingDashboard}
                  />

                  {/* /////  SECURITY  ///// */}
                  <Route exact path="/security" component={SecurityDashboard} />
                  <Route
                    exact
                    path="/myaccount/security"
                    component={SecurityDashboard}
                  />

                  {/* /////  SETTINGS  ///// */}
                  <Route exact path="/settings" component={SettingsDashboard} />
                  <Route
                    exact
                    path="/myaccount/companydetails"
                    component={SettingsDashboard}
                  />

                  {/* /////  PLANS  ///// */}
                  <Route exact path="/plans" component={PlanSelection} />
                  <Route
                    exact
                    path="/myaccount/billing/manage-plan"
                    component={PlanSelection}
                  />

                  {/* /////  CHECK-OUT  ///// */}
                  <Route exact path="/checkout" component={Checkout} />

                  {/* /////  BOOKKEEPER CHECKOUT  ///// */}
                  <Route
                    exact
                    path="/checkout/bookkeeper"
                    component={BookkeeperCheckout}
                  />

                  {/* /////  REPORTS  ///// */}
                  <Route exact path="/reports" component={ReportsDashboard} />

                  <Route exact path={PAGE_ROUTES.INVITES} component={InviteList} />

                  {/* /////  ALL-OTHERS ///// */}
                  <Route path="/" component={AdminDashboard} />
                </Switch>
              </Router>
            </div>
          )}
        </div>
      </div>
    );
  }
  showEmailVerificationPopup = () => {
    showAlert(
      `Verify your email address`,
      `We have sent an email to <b>${UserManager.getUserEmail()}</b>, If you don't see it, please check your spam folder`,
      [
        {
          title: "Do it later",
          className: "bg-gray1  border-m mt-r",
        },
        {
          title: "Resend email",
          onClick: () => this.resendVerificationEmail(),
          className: "bg-blue  text-white ml-r mt-r",
        },
      ]
    );
  };
  resendVerificationEmail = () => {
    IAM.resendVerificationEmail().then(
      (data) => {},
      (err) => {}
    );
  };

  addIntercom = () => {
    User.getIntercomHash().then((res) => {
      let user = {
        app_id: process.env.REACT_APP_INTERCOM_ID,
        user_hash: res.hash,
        email: UserManager.getUserEmail(),
        name: UserManager.getUserName(),
        company: {
          company_id: UserManager.getUserTenantID(),
          name: UserManager.getUserTenantName(),
        },
      };

      if (window.Intercom) {
        IntercomAPI("boot", user);
      }
    });
  };

  addCookieConsent = () => {
    setTimeout(() => {
      if (
        typeof window !== "undefined" &&
        window.cookieconsent !== undefined &&
        window.cookieconsent.run !== undefined
      ) {
        window.cookieconsent.run({
          notice_banner_type: "simple",
          consent_type: "express",
          palette: "light",
          language: "en",
          cookies_policy_url: "https://www.deskera.com/cookie-policy",
        });
      }
    }, 1000);
  };
}

export default App;
