import ApiConstants from "../constants/ApiConstants"
import httpClient  from "../http"
import Utility from "../utility/Utility";
export const checkCode = (payload:any) => {
    return httpClient.post(ApiConstants.URL.CREDIT.CHECK_CODE, payload);
}
export const applyCode = (payload:any) => {
    return httpClient.post(ApiConstants.URL.CREDIT.APPLY, payload);
}
export const getPricing = (payload:any) => {
    return httpClient.post(ApiConstants.URL.CREDIT.PRICING, payload);
}
export const getBillingDetails = () => {
    return httpClient.get(ApiConstants.URL.CREDIT.BILLING_DETAILS);
}
export const isCreditBasedPlan = (subscription: any): boolean => {
    return !Utility.isEmpty(subscription?.CreditsApplied);
}