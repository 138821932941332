import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  DKLabel,
  DKIcon,
  DKDataGrid,
  DKIcons,
  showAlert,
  showLoader,
  removeLoader
} from "deskera-ui-library";
import RouteManager from "../../managers/RouteManager";
import AppManager from "../../managers/AppManager";
import SideBarService from "../../services/sidebar";
import InviteService from "../../services/InviteService";
import InviteManager from "../../managers/InviteManager";
import { PRODUCTS, PRODUCT_CODE_REPLACE } from "../../constants/Enum";
import ApiConstants from "../../constants/ApiConstants";
import { isComplianceCountry } from "../common/CommonUtils";
import ic_no_data_3 from "../../assets/icons/ic_no_data_3.png";

function InviteList(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const width = SideBarService.getContainerWidth() - 20;

  const [columns, setColumns] = useState([]);
  const [invites, setInvites] = useState([]);

  useEffect(() => {
    RouteManager.setPresenter({ props: { ...props, history: history } });
    AppManager.scrollToTop();

    getColumns();
    getInvites();
  }, []);

  const getColumns = () => {
    const columns = [...InviteManager.getColumns()];
    columns.forEach((column) => {
      column.name = t(column.name);

      if (column.key === "action") {
        column.options = [
          {
            title: t("ACCEPT"),
            className: "bg-blue text-white fw-m mr-m",
            onClick: ({ rowData }) => {
              acceptInvite(rowData);
            },
          },
        ];
      }
    });

    setColumns(columns);
  };

  const getRowData = (invites) => {
    const rowData = [];
    if (Array.isArray(invites)) {
      invites.forEach((invite, idx) => {
        rowData.push({
          ...invite,
          sn: idx + 1,
          rowContextMenu: getRowContextMenu(),
        });
      });
    }

    return rowData;
  };

  const getRowContextMenu = () => {
    return [
      {
        title: t("REJECT"),
        icon: DKIcons.ic_close,
        className: " p-0",
        onClick: ({ rowData }) => {
          rejectInvite(rowData.id);
        },
      },
    ];
  };

  const getInvites = () => {
    showLoader();
    InviteService.getInvites().then(
      (response) => {
        removeLoader();
        setInvites(getRowData(response));
      },
      (error) => {
        removeLoader();
        setInvites([]);
      }
    );
  };
  const gotoProductURL = (product) => {
    let url = null;
    switch (product) {
      case PRODUCTS.ERP:
        url = isComplianceCountry(product)
          ? ApiConstants.PRODUCT_URL_ERP_PLUS
          : ApiConstants.PRODUCT_URL_ERP;
        break;
      case PRODUCTS.CRM:
        url = ApiConstants.PRODUCT_URL_CRM_PLUS;
        break;
      case PRODUCTS.PEOPLE:
      case PRODUCTS.PEOPLE_PLUS:
        url = isComplianceCountry(product)
          ? ApiConstants.PRODUCT_URL_PEOPLE_PLUS
          : ApiConstants.PRODUCT_URL_PEOPLE;
        break;
      case PRODUCTS.REPORT_BUILDER:
        url = ApiConstants.PRODUCT_URL_REPORT_BUILDER;
        break;
      default:
        url = null;
    }

    if (url) {
      window.open(url, "_blank");
    }
  };
  const acceptInvite = (rowData) => {
    showLoader('Accepting... Please Wait.')
    InviteService.acceptInvite(rowData.inviteCode).then(
      (response) => {
        removeLoader()
        console.log(response);
        const appName =
          rowData.appName?.toLowerCase() &&
          PRODUCT_CODE_REPLACE[rowData.appName?.toLowerCase()]
            ? PRODUCT_CODE_REPLACE[rowData.appName?.toLowerCase()]
            : rowData.appName?.toLowerCase();
        gotoProductURL(appName);
        getInvites();
      },
      (error) => {
        removeLoader();
      }
    );
  };

  const rejectInvite = (inviteId) => {
    InviteService.rejectInvite(inviteId).then(
      (response) => {
        getInvites();
      },
      (error) => { }
    );
  };

  const noDataView = () => {
      return (
          <div className="column align-items-center" style={{ pointerEvents: 'none', paddingBottom: "4vh", marginTop: "-4vh" }} >
              <DKIcon src={ic_no_data_3} className="ic-m" style={{ opacity: 0.2, marginTop: 90 }} />
              <DKLabel text="No invites found" className="fw-m mt-m" />
              <DKLabel text="Once data is available, it will appear here" className="text-gray mt-s " />
          </div>
      );
  };

  return (
    <div className="parent-width" style={{ height: "100wh" }}>
      <div className="parent-width row fw-m fs-l">{t("INVITES")}</div>
      {invites?.length > 0 &&
        <DKDataGrid
          allowSearch={false}
          allowBulkOperation={false}
          needTrailingColumn={true}
          allowColumnSort={false}
          allowColumnEdit={false}
          columns={columns}
          rows={invites}
          width={width}
        />
      }
      {invites?.length === 0 && noDataView()}
    </div>
  );
}

export default InviteList;
